import { render, staticRenderFns } from "./CategoriesPanel.vue?vue&type=template&id=795fc57b&scoped=true&"
import script from "./CategoriesPanel.vue?vue&type=script&lang=ts&"
export * from "./CategoriesPanel.vue?vue&type=script&lang=ts&"
import style0 from "./CategoriesPanel.vue?vue&type=style&index=0&id=795fc57b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795fc57b",
  null
  
)

export default component.exports