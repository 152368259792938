































































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import SygniDroparea from '@/components/inputs/SygniDroparea.vue';
import { NewCaseData } from '../store/types';
import { AxiosRequestConfig } from 'axios';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { required } from 'vuelidate/lib/validators';

Component.registerHooks(['validations'])
@Component({
  components: { SygniContainerTitle, SygniSelect, SygniInput, SygniTextArea, SygniDroparea, SygniFileBox, SygniRoundedButton },
})
export default class CreateCaseModal extends Vue {
  @Prop() isLoading!: boolean;

  uploadProgress: UploadProgress = { progress: 0 };
  files: Array<any> = [];
  uploadedFiles: Array<any> = [];
  areFilesLoading: boolean = false;

  formData: NewCaseData = {
    category: '',
    title: '',
    description: '',
    name: '',
    surname: '',
    email: '',
    phone: '',
  }

  deleteFile(file: string) {
    const index = this.uploadedFiles.findIndex(el => el == file);
    this.uploadedFiles.splice(index, 1);
  }

  async addFiles(files: FileList) {
    this.files = [];

    Array.from(files).forEach((file: File) => {
      this.files.push(file);
    });

    await this.uploadFiles();
  }

  formatFormData() {
    this.formData.name = (this.formData.name == '') ? null : this.formData.name;
    this.formData.surname = (this.formData.surname == '') ? null : this.formData.surname;
    this.formData.email = (this.formData.email == '') ? null : this.formData.email;
    this.formData.phone = (this.formData.phone == '') ? null : this.formData.phone;
  }

  async uploadFiles() {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      this.areFilesLoading = true;
      const promises: Array<Promise<any>> = [];
      this.files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch('genprox/uploadFile', {file, config}));
      })

      const uploadedFiles = await Promise.all(promises);
      this.areFilesLoading = false;
      uploadedFiles.forEach((file: File) => {
        this.uploadedFiles.push(file);
      });
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Files have been successfully uploaded'
      });
    } catch (e) {
      e;
    }
    this.uploadProgress.progress = -1;
  }

  async createNewCase() {
    this.$v.$touch();
    if (this.$v.$error) {
      return;
    }

    this.formatFormData();

    try {
      const caseId = await this.$store.dispatch('whistleblower/createCase', this.formData);
  
      const documentIds = this.uploadedFiles.map((el: any) => {
        return el.id;
      })
  
      await this.$store.dispatch('whistleblower/uploadCaseDocument', {
        id: caseId, 
        documentIds: documentIds
      });
  
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Case created.',
      })
  
      this.closeModal();
    } catch(e) {
      this.$notify({
        type: 'error',
        title: 'Case cannot be created.',
        text: 'Something went wrong. Please try again later.'
      });
    }
  }

  closeModal() {
    this.$emit('close');
    this.uploadedFiles = [];
    this.files = [];
    this.formData = {
      category: '',
      title: '',
      description: '',
      name: '',
      surname: '',
      email: '',
      phone: '',
    }
    this.$v.$reset();
  }

  get categories() {
    return this.$store.getters['whistleblower/getCategories'];
  }

  validations () {
    return {
      formData: {
        category: { required },
        title: { required },
      }
    }
  }
}
