import { render, staticRenderFns } from "./WhistleblowerTable.vue?vue&type=template&id=653e5144&scoped=true&"
import script from "./WhistleblowerTable.vue?vue&type=script&lang=ts&"
export * from "./WhistleblowerTable.vue?vue&type=script&lang=ts&"
import style0 from "./WhistleblowerTable.vue?vue&type=style&index=0&id=653e5144&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653e5144",
  null
  
)

export default component.exports