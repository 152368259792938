var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-filters"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "CASE TOKEN"
    },
    model: {
      value: _vm.filters.token.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.token, "value", $$v);
      },
      expression: "filters.token.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "range": "true",
      "label": "DATE"
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.categories,
      "label": "CATEGORY"
    },
    model: {
      value: _vm.filters.category.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.category, "value", $$v);
      },
      expression: "filters.category.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "case-status"
  }, [_c('sygni-multi-select', {
    attrs: {
      "options": _vm.caseStatusOptions,
      "multiple": true,
      "display-values": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "select-all": true,
      "label": 'CASE STATUS'
    },
    on: {
      "input": _vm.updateStatuses,
      "toggleAll": _vm.toggleAllStatuses
    },
    model: {
      value: _vm.selectedCaseStatusOptions,
      callback: function callback($$v) {
        _vm.selectedCaseStatusOptions = $$v;
      },
      expression: "selectedCaseStatusOptions"
    }
  })], 1)]), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "hero-filters__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": _vm.applyFilters
    }
  }, [_vm._v("Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('sygni-link-button', {
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.clearAll
    }
  }, [_vm._v("Clear all")])], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }